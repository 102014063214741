import { Message } from 'element-ui'
import axios from 'axios'
import {
  getLocalStorageSync,
  removeLocalStorageSync,
  setLocalStorageSync
} from './index'
// 创建axios实例
var instance = axios.create({
  baseURL: '/api',
  timeout: 1000 * 30,
})
import { resetRouter } from '@/router/index'
// 设置post请求头
instance.defaults.headers.post['Content-Type'] =
  'application/json; charset=utf-8'

/**
 * 请求拦截器
 * 每次请求前，如果存在token则在请求头中携带token
 */
instance.interceptors.request.use(
  (config) => {
    const token = getLocalStorageSync('token')
    if (token && typeof token !== 'undefined') {
      config.headers.Authorization = token
    }
    return config
  },
  (error) => Promise.error(error)
)

// 响应拦截器
instance.interceptors.response.use(
  (res) => {
    if (
      res.data == null &&
      res.config.responseType === 'json' &&
      res.request.responseText != null
    ) {
      try {
        // eslint-disable-next-line no-param-reassign
        res.data = JSON.parse(res.request.responseText)
      } catch (e) {
        // ignored
      }
    }
    if (res.data.code == 200) {
      return res.data
    } else if (res.data.code == 403) {
      return refreshToken().then(res => {
        // 刷新token成功，将最新的token更新到header中，同时保存在localStorage中
        const { token, refToken } = res.data
        setLocalStorageSync('token', token)
        setLocalStorageSync('refToken', refToken)
        // 获取当前失败的请求
        const config = res.config
        // 重置一下配置
        config.headers['Authorization'] = token
        config.baseURL = '' // url已经带上了/api，避免出现/api/api的情况
        // 重试当前请求并返回promise
        return instance(config)
      }).catch(res => {
        removeLocalStorageSync('token')
        removeLocalStorageSync('refToken')
        resetRouter()
        //刷新token失败，神仙也救不了了，跳转到首页重新登录吧
        window.location.href = '/'
      })
    }
    else {
      Message({
        message: res.data.msg,
        type: 'error',
        showClose: false,
        duration: 2000,
      })
      return null
    }
  },
  (err) => {
    if (err && err.response) {
      switch (err.response.status) {
        case 400:
          err.message = '错误请求'
          break
        case 401:
          err.message = '未授权，请重新登录'
          removeLocalStorageSync('token')
          removeLocalStorageSync('refToken')
          resetRouter()
          window.location.reload()
          break
        case 403:
          err.message = '拒绝访问'
          break
        case 404:
          err.message = '请求错误,未找到该资源'
          break
        case 405:
          err.message = '请求方法未允许'
          break
        case 408:
          err.message = '请求超时'
          break
        case 500:
          err.message = '服务器端出错'
          break
        case 501:
          err.message = '网络未实现'
          break
        case 502:
          err.message = '网络错误'
          break
        case 503:
          err.message = '服务不可用'
          break
        case 504:
          err.message = '网络超时'
          break
        case 505:
          err.message = 'http版本不支持该请求'
          break
        default:
          err.message = `连接错误${err.response.status}`
      }
    } else {
      err.message = '连接到服务器失败'
    }
    Message({
      message: err.message,
      type: 'error',
      showClose: true,
      duration: 2000,
    })
    return Promise.resolve(err.response)
  }
)


function refreshToken() {
  // instance是当前request.js中已创建的axios实例
  const refToken = getLocalStorageSync('refToken')
  return instance.post('/refreshLogin', { refToken: refToken }).then(res => res.data)
}
export default instance
