<template>
  <div class="aside">
    <el-menu :default-active="activeUrl" active-text-color="#409eff" @select="select">
      <template v-for="(item, index) in menuData">
        <el-menu-item :index="item.path" v-if="item.path != ''" :key="index">
          <i :class="item.icon"></i>
          <span slot="title">{{ item.name }}</span>
        </el-menu-item>
        <el-submenu :index="'key' + index" v-else :key="index">
          <template slot="title">
            <i :class="item.icon"></i>
            <span>{{ item.name }}</span>
          </template>
          <el-menu-item :index="obj.path" v-for="obj in item.data" :key="obj.code">{{ obj.name }}</el-menu-item>
        </el-submenu>
      </template>
    </el-menu>
  </div>
</template>

<script>
const menu = [{
  icon: 'el-icon-location',
  path: '/article',
  name: '文章管理',
  data: [],
  code: 'article'
}, {
  icon: 'el-icon-location',
  path: '/activity',
  name: '活动管理',
  data: [],
  code: 'activity'
}, {
  icon: 'el-icon-location',
  path: '/log',
  name: '随手笔记',
  data: [],
  code: 'log'
}, {
  icon: 'el-icon-location',
  path: '/score',
  name: '积分管理',
  data: [],
  code: 'score'
},
{
  icon: 'el-icon-location',
  path: '',
  name: '镇管理',
  code: '',
  data: [
    {
      icon: '',
      path: '/town',
      name: '镇管理',
      code: 'town'
    },
    {
      icon: '',
      path: '/town/personnel',
      name: '人员管理',
      code: 'townPersonnel'
    },
    {
      icon: '',
      path: '/town/role',
      name: '角色管理',
      code: 'townRole'
    },
    {
      icon: '',
      path: '/country',
      name: '村委档案管理',
      code: 'country'
    },
  ]
}, {
  icon: 'el-icon-location',
  path: '',
  name: '村委管理',
  code: '',
  data: [
    {
      icon: '',
      path: '/country/personnel',
      name: '人员管理',
      code: 'countryPersonnel'
    },
    {
      icon: '',
      path: '/country/family',
      name: '家庭管理',
      code: 'countryFamily'
    },
    {
      icon: '',
      path: '/country/role',
      name: '角色管理',
      code: 'countryRole'
    },
  ]
},
{
  icon: 'el-icon-location',
  path: '/report',
  name: '报表中心',
  data: [],
  code: 'report'
},
{
  icon: 'el-icon-location',
  path: '/take',
  name: '接龙活动管理',
  data: [],
  code: 'take'
},
]
export default {
  data() {
    return {
      activeUrl: '',
      menuData: []
    }
  },
  watch: {
    $route(to, from) {
      this.getActiveUrl()
    },
  },
  created() {
    let menuData = []

    const tagList = this.$store.state.userInfo.tagList

    for (let i = 0; i < menu.length; i++) {
      if (menu[i].path.length === 0) {
        let data = []
        for (let j = 0; j < menu[i].data.length; j++) {
          const element = menu[i].data[j];
          const obj = tagList.find(c => c.code === element.code)
          if (obj) {
            data.push(menu[i].data[j])
          }
        }

        if (data.length > 0) {
          menuData.push({ ...menu[i], data: data })
        }
      }
      else {
        const obj = tagList.find(c => c.code === menu[i].code)
        if (obj) {
          menuData.push(menu[i])
        }
      }
    }
    this.menuData = menuData

    this.getActiveUrl()
  },
  methods: {
    select(index) {
      if (this.$route.path !== index) {
        this.$router.push(index)
      }
    },
    getActiveUrl() {
      this.activeUrl = this.$route.meta.path
    },
  },
}
</script>

<style lang="scss" scoped>
.aside {
  width: 100%;
  height: 100%;
  overflow: auto;
}
</style>
