import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate"

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    userInfo: null
  },
  getters: {},
  mutations: {
    setUserInfo(state, value) {
      state.userInfo = value
    }
  },
  actions: {},
  modules: {},
  plugins: [createPersistedState()],
});
