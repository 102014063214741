import Vue from "vue";
import store from '@/store/index'
Vue.directive('permission', {
    inserted(el, binding) {
        const code = binding.value.code
        const effect = binding.value.effect
        // 判断当前路由对应的组件当中，当前用户拥有的权限
        let tagList =  store.state.userInfo.tagList;
        // console.log(meta, '当前路由下组件对应的用户权限');
        // 手写的和后端权限数据进行条件判断，找不到代表没有权限，进行相关操作
        const obj = tagList.find(c=>c.code === code)
        if (obj) {
            if (effect === 'disabled') {
                el.disabled = true
                el.classList.add('is-disabled')
            } else {
                el.parentNode.removeChild(el)
            }
        }
    }
})