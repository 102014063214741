<template>
  <div class="header">
    <div class="title">新丰马头镇基层治理系统</div>
    <div class="date-content">
      <i class="el-icon-date"></i>
      <div class="date-label">{{ date }}</div>
      <div class="week-label">{{ week }}</div>
    </div>
    <div class="user-content">
      <div class="user-img">
        <i class="el-icon-user-solid"></i>
      </div>

      <el-dropdown @command="handleCommand">
        <div class="user-name">
          <label>{{ userInfo.name }}</label>
          <i class="el-icon-caret-bottom"></i>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="logout">退出系统</el-dropdown-item>
          </el-dropdown-menu>
        </div>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import { removeStorageSync } from '@/utils/index'
import http from '@/utils/http'
import {resetRouter} from '@/router/index'
export default {
  data() {
    return {
      date: '',
      week: '',
      userInfo: this.$store.state.userInfo
    }
  },
  created() {
    this.getDate()
    if (!this.userInfo) {
      this.getUserInfo()
    }
  },
  methods: {
    getUserInfo() {
      http.post("/getUserInfo", null).then(res => {
        if (res) {
          this.userInfo = res.data
          this.$store.commit("setState", "userInfo", res.data)
        }
      })
    },
    getDate() {
      let that = this
      setTimeout(() => {
        const date = new Date()
        that.date = dayjs(date).format('YYYY-MM-DD')
        const weeks = [
          '星期日',
          '星期一',
          '星期二',
          '星期三',
          '星期四',
          '星期五',
          '星期六',
        ]
        that.week = weeks[date.getDay()]
        that.getDate()
      }, 1000)
    },
    handleCommand(command) {
      if (command == 'logout') {
        removeStorageSync('token')
        this.$router.push('/login')
        resetRouter()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.header {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;

  .title {
    font-size: 22px;
    font-weight: 700;
    color: #ffffff;
    flex: 1;
  }

  .date-content {
    display: flex;
    align-items: center;
    padding: 0px 12px;

    i {
      color: #ffffff;
    }

    .date-label {
      padding: 0px 6px;
      font-size: 14px;
      color: #ffffff;
    }

    .week-label {
      font-size: 14px;
      color: #ffffff;
    }
  }

  .user-content {
    display: flex;
    align-items: center;

    .user-img {
      width: 32px;
      height: 32px;
      border-radius: 32px;
      // border: 1px solid #f5f5f5;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20px;
      color: #ffffff;
    }

    .user-name {
      padding-left: 6px;
      color: #ffffff;
    }
  }
}
</style>
