import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '../layout/index.vue'
import { getLocalStorageSync } from '@/utils/index'

import store from '@/store/index'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login/index.vue'),
  },
  {
    path: '/',
    name: 'home',
    component: Layout,
    children: [
    ],
  }
]


const routeList = [
  {
    path: '/article',
    name: 'article',
    meta: {
      path: '/article',
      code: 'article'
    },
    component: () => import('../views/article/index.vue'),
  },
  {
    path: '/article/add',
    name: 'articleAdd',
    meta: {
      path: '/article',
      code: 'article'
    },
    component: () => import('../views/article/add.vue'),
  },
  {
    path: '/article/edit',
    name: 'articleEdit',
    meta: {
      path: '/article',
      code: 'article'
    },
    component: () => import('../views/article/edit.vue'),
  },
  {
    path: '/activity',
    name: 'activity',
    meta: {
      path: '/activity',
      code: 'activity'
    },
    component: () => import('../views/activity/index.vue'),
  },
  {
    path: '/activity/add',
    name: 'activityAdd',
    meta: {
      path: '/activity',
      code: 'activity'
    },
    component: () => import('../views/activity/add.vue'),
  },
  {
    path: '/activity/edit',
    name: 'activityEdit',
    meta: {
      path: '/activity',
      code: 'activity'
    },
    component: () => import('../views/activity/edit.vue'),
  },
  {
    path: '/log',
    name: 'log',
    meta: {
      path: '/log',
      code: 'log'
    },
    component: () => import('../views/log/index.vue'),
  },
  {
    path: '/score',
    name: 'score',
    meta: {
      path: '/score',
      code: 'score'
    },
    component: () => import('../views/score/index.vue'),
  },
  {
    path: '/town',
    name: 'town',
    meta: {
      path: '/town',
      code: 'town'
    },
    component: () => import('../views/town/index.vue'),
  },
  {
    path: '/town/personnel',
    name: 'townPersonnel',
    meta: {
      path: '/town/personnel',
      code: 'townPersonnel',
    },
    component: () => import('../views/town/personnel.vue'),
  },
  {
    path: '/town/role',
    name: 'townRole',
    meta: {
      path: '/town/role',
      code: 'townRole',
    },
    component: () => import('../views/town/role.vue'),
  },
  {
    path: '/country',
    name: 'country',
    meta: {
      path: '/country',
      code: 'country',
    },
    component: () => import('../views/country/index.vue'),
  },
  {
    path: '/country/role',
    name: 'countryRole',
    meta: {
      path: '/country/role',
      code: 'countryRole',
    },
    component: () => import('../views/country/role.vue'),
  },
  {
    path: '/country/family',
    name: 'countryFamily',
    meta: {
      path: '/country/family',
      code: 'countryFamily',
    },
    component: () => import('../views/country/family.vue'),
  },
  {
    path: '/country/personnel',
    name: 'countryPersonnel',
    meta: {
      path: '/country/personnel',
      code: 'countryPersonnel',
    },
    component: () => import('../views/country/personnel.vue'),
  },
  {
    path: '/report',
    name: 'report',
    meta: {
      path: '/report',
      code: 'report',
    },
    component: () => import('../views/report/index.vue'),
  },
  {
    path: '/take',
    name: 'take',
    meta: {
      path: '/take',
      code: 'take',
    },
    component: () => import('../views/take/index.vue'),
  },
]

const createRouter = () => new VueRouter({
  routes,
})
const router = createRouter()

//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

router.beforeEach((to, from, next) => {
  const token = getLocalStorageSync('token')
  if (to.path == '/login') next()
  else {
    const arr = router.options.routes
    if (token) {
      if (arr[1].children.length === 0) {
        initDynamicRoutes()
        if (to.path === "/") {
          next(arr[1].children[0].path)
        } else {
          next(to.path)
        }
      } else {
        if (to.path === "/") {
          next(arr[1].children[0].path)
        } else {
          next()
        }
      }
    } else {
      next('/login')
    }
  }
})

//动态路由,登录成功后根据二级路由权限进行路由匹配动态添加
export function initDynamicRoutes() {
  const currentRoutes = router.options.routes
  let tagList = store.state.userInfo.tagList;

  for (let i = 0; i < routeList.length; i++) {
    const item = routeList[i];
    for (let j = 0; j < tagList.length; j++) {
      if (tagList[j].code == item.meta.code) {
        currentRoutes[1].children.push(item)
        break
      }
    }
  }

  router.addRoutes(currentRoutes)
  console.log(routes)
}

export function resetRouter() { //清空路由的方法
  const newRouter = createRouter()
  router.matcher = newRouter.matcher
}

export default router
