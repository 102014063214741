<template>
  <div class="layout">
    <el-container>
      <el-header height="54px">
        <Header></Header>
      </el-header>
      <el-container>
        <el-aside width="220px">
          <Aside></Aside>
        </el-aside>
        <el-main>
          <router-view></router-view>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>
<script>
import Aside from './components/aside.vue'
import Header from './components/header.vue'
export default {
  components: { Aside, Header },
}
</script>

<style lang="scss" scoped>
.layout {
  width: 100%;
  height: 100vh;
  background-color: #f5f5f5;
  .el-container {
    width: 100%;
    height: 100%;
    overflow: hidden;
    .el-header {
      background-color: #1885f1;
      box-shadow: 0px 6px 10px #eceaea;
    }
    .el-aside {
      background-color: #ffffff;
      box-shadow: 6px 0px 10px #eceaea;
    }
  }
}
</style>
