
// 本地存储-存数据
export const setStorageSync = (key, value) => window.sessionStorage.setItem(key, JSON.stringify(value));

// 本地存储-取数据
export const getStorageSync = key => JSON.parse(window.sessionStorage.getItem(key));

// 本地存储-删数据
export const removeStorageSync = key => window.sessionStorage.removeItem(key);


// 本地存储-存数据
export const setLocalStorageSync = (key, value) => window.localStorage.setItem(key, value);

// 本地存储-取数据
export const getLocalStorageSync = key => window.localStorage.getItem(key);

// 本地存储-删数据
export const removeLocalStorageSync = key => window.localStorage.removeItem(key);

export const isNumber = (val) => {

    var regPos = /^[0-9]+.?[0-9]*/; //判断是否是数字。

    if (regPos.test(val)) {
        return true;
    } else {
        return false;
    }
}
